.price .page-header .img {
  max-width: 88px;
}
.price__block {
  margin: 0;
  padding: 35px 15px;
}
.price__block .inner {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1100px;
}
.price__block__ttl {
  font-size: 23px;
  font-weight: 700;
  text-align: center;
}
.price__block__lead {
  margin-top: 30px;
}
.price__block ol > li {
  margin-left: 1.5em;
  list-style: decimal outside;
}
.price__anchor {
  margin: -15px auto 10px;
}
.price__cont {
  margin-top: 40px;
}
.price__cont__ttl {
  font-size: 18px;
  text-align: center;
}
.price__cont__subTtl {
  margin-top: 22px;
  padding-left: 13px;
  color: #0072BC;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.05;
  position: relative;
}
.price__cont__subTtl::before {
  content: "";
  display: block;
  width: 3px;
  height: 15px;
  background-color: #0072BC;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.price__cont__lead {
  margin-top: 20px;
}
.price__cont__name {
  margin-top: 28px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.price__cont table {
  margin-top: 25px;
}
.price__cont .notice {
  margin-top: 38px;
}

@media screen and (min-width: 768px) {
  .price .page-header .img {
    max-width: 174px;
    right: 13px;
  }
  .price__block {
    padding: 75px 15px 87px;
  }
  .price__block__ttl {
    font-size: 35px;
  }
  .price__block__lead {
    margin-top: 56px;
    text-align: center;
  }
  .price__anchor {
    margin: -15px auto 10px;
  }
  .price__cont {
    margin-top: 75px;
  }
  .price__cont__ttl {
    font-size: 24px;
    text-align: center;
  }
  .price__cont__subTtl {
    margin-top: 44px;
    padding-left: 22px;
    font-size: 20px;
    letter-spacing: 0.05em;
  }
  .price__cont__subTtl::before {
    width: 7px;
    height: 24px;
  }
  .price__cont__lead {
    margin-top: 34px;
    text-align: center;
  }
  .price__cont__name {
    margin-top: 0;
    font-size: 24px;
    text-align: left;
  }
  .price__cont table {
    margin-top: 25px;
  }
  .price__cont .notice {
    margin-top: 38px;
  }
}
@media screen and (min-width: 768px) {
  .intro .leadTxt {
    text-align: center;
  }
}
.price01 {
  padding: 64px 15px 55px;
}
.price01 table.tbl_1 {
  margin-top: 25px;
}
.price01 table.tbl_1 thead th {
  padding: 15px 0;
}
.price01 table.tbl_1 thead th.blue {
  background-color: #0072BC;
}
.price01 table.tbl_1 tbody th {
  background-color: #F1F6F9;
}
.price01 table.tbl_1 tbody td.blue {
  color: #0072BC;
}
.price01 table.tbl_2 {
  margin-top: 22px;
}
.price01 table.tbl_2 tbody th, .price01 table.tbl_2 tbody td {
  height: 59px;
}
.price01 table.tbl_2 tbody th {
  background-color: #F1F6F9;
}
.price01 table.tbl_3 {
  margin-top: 22px;
}
.price01 table.tbl_4 {
  margin-top: 22px;
}
.price01 .cont_1 .notice {
  margin-top: 38px;
}
.price01 .cont_2 {
  margin-top: 50px;
}
.price01 .cont_2 .notice {
  margin-top: 36px;
}
.price01 .cont_2 .notice ul {
  margin-top: 4px;
}
.price01 .cont_3 {
  margin-top: 48px;
}
.price01 .cont_3 .price__cont__txt01 {
  margin-top: 10px;
}
.price01 .cont_3 .price__cont__txt02 {
  margin-top: 12px;
}
.price01 .cont_3 .price__cont__txt03 {
  margin-top: 12px;
  font-size: 13px;
}
.price01 .cont_4 {
  margin-top: 48px;
}
.price01 .cont_4 .price__cont__txt01 {
  margin-top: 18px;
}

@media screen and (min-width: 768px) {
  .price01 {
    padding: 115px 15px 120px;
  }
  .price01 table.tbl_1 {
    margin-top: 35px;
  }
  .price01 table.tbl_1 thead th:nth-of-type(1) {
    width: 24%;
  }
  .price01 table.tbl_1 thead th:nth-of-type(2) {
    width: 38%;
    font-size: 20px;
  }
  .price01 table.tbl_1 thead th:nth-of-type(3) {
    width: 38%;
    font-size: 20px;
  }
  .price01 table.tbl_2 {
    margin-top: 35px;
  }
  .price01 table.tbl_2 thead th:nth-of-type(1) {
    width: 24%;
  }
  .price01 table.tbl_2 thead th:nth-of-type(2) {
    width: 38%;
    font-size: 20px;
  }
  .price01 table.tbl_2 thead th:nth-of-type(3) {
    width: 38%;
    font-size: 20px;
  }
  .price01 table.tbl_2 tbody th, .price01 table.tbl_2 tbody td {
    height: 70px;
  }
  .price01 .cont_1 {
    margin: 75px auto 0;
    max-width: 900px;
  }
  .price01 .cont_1 .notice {
    margin-top: 51px;
  }
  .price01 .cont_2 {
    margin: 96px auto 0;
    max-width: 900px;
  }
  .price01 .cont_2 .notice {
    margin-top: 56px;
  }
  .price01 .cont_2 .notice ul {
    margin-top: 13px;
  }
  .price01 .cont_3 {
    margin: 95px auto 0;
    max-width: 900px;
  }
  .price01 .cont_3 .price__cont__txt01 {
    margin-top: 17px;
  }
  .price01 .cont_3 .price__cont__txt02 {
    margin-top: 17px;
  }
  .price01 .cont_3 .price__cont__txt03 {
    margin-top: 16px;
  }
  .price01 .cont_4 {
    margin: 91px auto 0;
    max-width: 900px;
  }
  .price01 .cont_4 .price__cont__txt01 {
    margin-top: 33px;
    text-align: center;
  }
}
.price02 {
  padding: 64px 15px 50px;
  background-color: #F1F6F9;
}
.price02 table.tbl_3 {
  margin-top: 22px;
}
.price02 .cont_1 {
  margin-top: 28px;
}
.price02 .cont_1 .price__cont__feature {
  margin-top: 15px;
}
.price02 .cont_1 .feature__list + .feature__list {
  margin-top: 20px;
}
.price02 .cont_2 {
  margin-top: 36px;
}
.price02 .cont_3 {
  margin-top: 34px;
}
.price02 .cont_3 .notice {
  margin-top: 34px;
}

@media screen and (min-width: 768px) {
  .price02 {
    padding: 115px 15px 110px;
  }
  .price02 table.tbl_3 {
    margin-top: 35px;
  }
  .price02 table.tbl_3 thead th:nth-of-type(1) {
    width: 18%;
  }
  .price02 table.tbl_3 thead th:nth-of-type(2) {
    width: 37%;
    font-size: 20px;
  }
  .price02 table.tbl_3 thead th:nth-of-type(3) {
    width: 45%;
    font-size: 20px;
  }
  .price02 .cont_1 {
    margin: 60px auto 0;
  }
  .price02 .cont_1 .flexBox {
    display: flex;
    justify-content: space-between;
  }
  .price02 .cont_1 .flexItem.img {
    width: 41%;
  }
  .price02 .cont_1 .flexItem.textarea {
    width: 52%;
  }
  .price02 .cont_1 .price__cont__feature {
    margin-top: 50px;
  }
  .price02 .cont_2 {
    margin: 56px auto 0;
    max-width: 900px;
  }
  .price02 .cont_2 .price__cont__txt01 {
    text-align: center;
  }
  .price02 .cont_3 {
    margin: 80px auto 0;
    max-width: 900px;
  }
}
.price03 {
  padding: 64px 15px 50px;
}
.price03 table.tbl_4 {
  margin-top: 22px;
}
.price03 table.tbl_4 thead th {
  height: 52px;
}
.price03 .cont_1 {
  margin-top: 30px;
}
.price03 .cont_1 .price__cont__txt01 {
  margin-top: 30px;
}
.price03 .cont_1 .note {
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .price03 {
    padding: 115px 15px 95px;
  }
  .price03 table.tbl_4 {
    margin-top: 32px;
  }
  .price03 table.tbl_4 thead th {
    height: 72px;
  }
  .price03 table.tbl_4 thead th:nth-of-type(1) {
    width: 50%;
    font-size: 20px;
  }
  .price03 table.tbl_4 thead th:nth-of-type(2) {
    width: 50%;
    font-size: 20px;
  }
  .price03 table.tbl_4 tbody th {
    height: 72px;
  }
  .price03 .cont_1 {
    margin: 0 auto 0;
    max-width: 900px;
  }
  .price03 .cont_1 .price__cont__txt01 {
    margin-top: 56px;
    text-align: center;
  }
  .price03 .cont_1 .note {
    margin-top: 20px;
  }
}